import Bird from './images/bird.svg';
import Cat from './images/cat.svg';
import Default from './images/default.svg';
import Dog from './images/dog.svg';
import Horse from './images/horse.svg';
import Rabbit from './images/rabbit.svg';
import Reptile from './images/reptile.svg';

// eslint-disable-next-line import/prefer-default-export
export const PET_TO_PET_ILLUSTRATION = {
    bird: Bird,
    cat: Cat,
    default: Default,
    dog: Dog,
    horse: Horse,
    rabbit: Rabbit,
    reptile: Reptile,
};
