import React from 'react';

import PetIllustration from './PetIllustration';

export const PetBirdIllustration = ({ className }) => (
    <PetIllustration pet="bird" className={className} />
);

export const PetCatIllustration = ({ className }) => (
    <PetIllustration pet="cat" className={className} />
);

export const PetDefaultIllustration = ({ className }) => (
    <PetIllustration pet="default" className={className} />
);

export const PetDogIllustration = ({ className }) => (
    <PetIllustration pet="dog" className={className} />
);

export const PetHorseIllustration = ({ className }) => (
    <PetIllustration pet="horse" className={className} />
);

export const PetRabbitIllustration = ({ className }) => (
    <PetIllustration pet="rabbit" className={className} />
);

export const PetReptileIllustration = ({ className }) => (
    <PetIllustration pet="reptile" className={className} />
);

export default PetIllustration;
