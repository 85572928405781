import React from 'react';
import { useTranslation } from 'react-i18next';

import { PET_TO_PET_ILLUSTRATION } from './PetIllustration.constants';

const PetIllustration = ({ pet, className }) => {
    const { t } = useTranslation();

    const illustration = PET_TO_PET_ILLUSTRATION[pet];

    if (!illustration) return null;

    return (
        <img
            srcSet={`${illustration} 60w, ${illustration} 120w, ${illustration} 180w`}
            sizes="60w, 120w, 180w"
            alt={t(`components_images_petIllustration_alt_${pet}`)}
            className={className}
        />
    );
};

export default PetIllustration;
